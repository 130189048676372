import React from "react";
import { graphql, useStaticQuery } from "gatsby";

const SchoolofaiCase = () => {
  const data = useStaticQuery(graphql`
    query {
      allStagesJson(filter: { siteTitle: { eq: "schoolofai" } }) {
        edges {
          node {
            title
          }
        }
      }
    }
  `);

  // console.log(data);

  return <h1>{data.allStagesJson.edges[0]?.node.title || "No Title"}</h1>;
};

export default SchoolofaiCase;
